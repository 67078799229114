import React from "react";
import Slider from "../components/Slider/Slider";

const Home = () => {
  return (
    <>
      <Slider />
    </>
  );
};

export default Home;
