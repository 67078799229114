export const actionsTypes = {
  SET_CURRENT_LANGUAGE: "SET_CURRENT_LANGUAGE",
  GET_UNDERBUST_CORSET_DATA: "GET_UNDERBUST_CORSET_DATA",
  GET_OVERBUST_CORSET_DATA: "GET_OVERBUST_CORSET_DATA",
  GET_WEDDING_CORSET_DATA: "GET_WEDDING_CORSET_DATA",
  GET_EVENING_CORSET_DATA: "GET_EVENING_CORSET_DATA",
  GET_MESH_CORSET_DATA: "GET_MESH_CORSET_DATA",
  GET_SILK_CORSET_DATA: "GET_SILK_CORSET_DATA",
  GET_OTHER_CORSET_DATA: "GET_OTHER_CORSET_DATA",
  GET_DESIGNER_DATA: "GET_DESIGNER_DATA",
  GET_FABRICS_DATA: "GET_FABRICS_DATA",
  GET_QUESTIONS_AND_ANSWERS_DATA: " GET_QUESTIONS_AND_ANSWERS_DATA",
  GET_MEASURING_DATA: "GET_MEASURING_DATA",
  GET_ABOUT_COLLECTION_DATA: "GET_ABOUT_COLLECTION_DATA",
};
